<template>
  <div>


    <div class="header">
        <img src="@/assets/doc-logo.png" alt="logo" class="logo" />
        <div class="header-title">
          <a href="/" class="btn">DOCTEUR-CALCUL.FR</a>
        </div>
        <div class="header-left">
          <a href="/specialite" class="btn">SPECIALITES</a>
          <a href="/conseils" class="btn">CONSEILS</a>
          <button class="btn"
          v-on:click="toggleNav()"
        aria-label="Effectuez une recherche"
        >RECHERCHE</button>
        </div>
      </div>

      <div id="recherche2">
        <button  v-on:click="toggledown()" class="btnret" ><img class="image" src="@/assets/arrow.png" alt="retour"></button>
      <div class="form__group field">
        <input
          type="input"
          class="form__field"
          v-on:keyup="search()"
          placeholder="Name"
          name="name"
          id="reser2"
          required
        />
        <label for="name" class="form__label">Recherche</label>
      </div>
      <div class="teli">
        <div
          class="liste_car"
          v-for="e in touti"
          :key="e.id"
          v-on:click="navigateArticle(e)"
        >
          <div class="calcul_name">
            <h3>
              <div>{{ e }}</div>
            </h3>
          </div>
        </div>
        <div
          class="liste_car"
          v-for="ee in calculi"
          :key="ee.id"
          v-on:click="navigateArticle2(ee.matiere, ee.calcul_name, ee.id)"
        >
          <div class="calcul_name">
            <h3>
              <div>{{ ee.calcul_name }}</div>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import listjson from "@/assets/calculs_medicaux4.json";
export default {
  name: "NavBar",
  components: {},
  data() {
    return {
      touti: [],
      listjson: listjson,
      calculi: [],
      error: [ 43, 93,  163,  181,          191,   242, 231, 239, 244,     313, 305, 309, 303, 293, 294,311  ,67 ],
    };
  },
  methods: {
    navigateArticle(specialite) {
      specialite = _.deburr(specialite);
      specialite = specialite.replace(/ /g, "");
      specialite = specialite.toLowerCase()
      let origin = window.location.origin;
      location.href = origin + "/specialite/" + specialite;
    },
    navigateArticle2(specialite, calcul, id) {
      calcul = _.deburr(calcul);
      calcul = calcul.replace(/ /g, "");
      calcul = calcul.replace(/[/]/g, "");
      specialite = _.deburr(specialite);
      specialite = specialite.replace(/ /g, "");

      let origin = window.location.origin;
      location.href =
        origin + "/specialite/" + specialite + "/" + calcul + "/" + id;
    },
    toggleNav() {
      var nav = document.getElementById("recherche2");

      if (nav.style.display === "") {
        nav.style.display = "block";
        document.getElementById("reser2").focus();
        document.getElementById("main").style.filter = "blur(5px)";


      } else {
        nav.style.display = "";
        document.getElementById("main").style.filter = "";

      }
    },
    toggledown() {
      var nav = document.getElementById("recherche2");
      if (nav.style.display === "block") {
        nav.style.display = "";
        document.getElementById("main").style.filter = "";
      }
    },
    search() {
      let input = document.getElementById("reser2").value;
      input = input.toLowerCase();

      input = _.deburr(input);

      let x = document.getElementsByClassName("liste_car");
      let i = 0;
      for (i = 0; i < x.length; i++) {
        if (!_.deburr(x[i].innerHTML.toLowerCase()).includes(input)) {
          x[i].style.display = "none";
        } else {
          x[i].style.display = "block";
        }
      }
    },
  },
  mounted() {
    let touti = this.listjson.map((item) => item.matiere);
    this.calculi = this.listjson;
    this.calculi = this.calculi.filter((item) => !this.error.includes(item.id));
    // only unique values
    this.touti = [...new Set(touti)];
  },
};


</script>

<style scoped>

#recherche2 {
  display: none;
  position: fixed;
  top: 20vh;
  left: center;
  width: 100%;
  z-index: 1;
  
}

.form__group {
  position: relative;
  padding: 15px 0 0;

  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #000000;
  outline: 0;
  font-size: 1.3rem;
  color: rgb(0, 0, 0);
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #000000;
}



  .header {
    display: flex;
    position: fixed;
    color: black;
    justify-content: space-between;

    text-decoration: none;
    align-items: center;

    /*border bottom like line*/
    border-bottom: 1px solid #000000;
    width: 100%;
    background: rgb(139,172,255);

  }

  .logo {
    display: inline;
    width: 3%;
    height: 3%;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .header-title a {
    text-decoration: none;
    color: black;
    font-size: 1.5vw;
    font-weight: 900;
  }

  .header-left a, button {
    color: black;
    margin-right: 40px;
    text-align: left;
    width: 50%;
    text-decoration: none;
    font-size: 1.1vw;
    font-weight: 900;
  }

  .header-left {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    text-align: left;
  }


  .btnret {
    position: relative;
    display: flex;
    padding: 10px 15px;
    text-decoration: none;

}


.image{
        display: flex;
  width: 5%;
  margin-top: 5vh;
    }

  button{
    background: transparent;
    border: none;
  }



</style>
