<template>
  <div>
    <div id="recherche">
      <div class="form__group field">
        <input
          type="input"
          class="form__field"
          v-on:keyup="search()"
          placeholder="Name"
          name="name"
          id="reser"
          required
        />
        <label for="name" class="form__label">Recherche</label>
      </div>
      <div class="teli">
        <div
          class="liste_car"
          v-for="e in touti"
          :key="e.id"
          v-on:click="navigateArticle(e)"
        >
          <div class="calcul_name">
            <h3>
              <div>{{ e }}</div>
            </h3>
          </div>
        </div>
        <div
          class="liste_car"
          v-for="ee in calculi"
          :key="ee.id"
          v-on:click="navigateArticle2(ee.matiere, ee.calcul_name, ee.id)"
        >
          <div class="calcul_name">
            <h3>
              <div>{{ ee.calcul_name }}</div>
            </h3>
          </div>
        </div>
      </div>
    </div>

    <nav class="menu float">
      <input
        type="checkbox"
        class="menu-open"
        name="menu-open"
        id="menu-open"
        v-on:click="toggledown()"
      />
      <label class="menu-open-button" for="menu-open">
        <span class="hamburger hamburger-1"></span>
        <span class="hamburger hamburger-2"></span>
        <span class="hamburger hamburger-3"></span>
      </label>

      <a href="/" class="menu-item" aria-label="Retournez à la page d'accueil">
        <i class="fa fa-home"></i>
      </a>
      <a
        href="/conseils"
        class="menu-item"
        aria-label="Allez à la page de conseil"
      >
        <i class="fa fa-lightbulb-o"></i>
      </a>
      <button
        class="menu-item"
        v-on:click="toggleNav()"
        aria-label="Effectuez une recherche"
      >
        <i class="fa fa-search"></i>
    </button>
      <a
        href="/specialite"
        class="menu-item"
        aria-label="Allez à la page des spécialités"
        ><img src="@/assets/logopipow.png" class="imgdoc" alt="Specialites" />
      </a>
      <!--<i class="fa fa-stethoscope"></i>-->
    </nav>
    <!-- filters -->
  </div>
</template>

<script>
import _ from "lodash";
import listjson from "@/assets/calculs_medicaux4.json";
export default {
  name: "BottomBar",
  components: {},
  data() {
    return {
      touti: [],
      listjson: listjson,
      calculi: [],
      error: [ 43, 93,  163,  181,          191,   242, 231, 239, 244,     313, 305, 309, 303, 293, 294,311  ,67 ],
    };
  },
  methods: {
    navigateArticle(specialite) {
      specialite = _.deburr(specialite);
      //lowercase
      specialite = specialite.toLowerCase();
      specialite = specialite.replace(/ /g, "");
     
    
      let origin = window.location.origin;
      location.href = origin + "/specialite/" + specialite;
    },
    navigateArticle2(specialite, calcul, id) {
      calcul = _.deburr(calcul);
      calcul = calcul.replace(/ /g, "");
      calcul = calcul.replace(/[/]/g, "");
      specialite = _.deburr(specialite);
      specialite = specialite.replace(/ /g, "");

      let origin = window.location.origin;
      location.href =
        origin + "/specialite/" + specialite + "/" + calcul + "/" + id;
    },
    toggleNav() {
      var nav = document.getElementById("recherche");

      if (nav.style.display === "") {
        nav.style.display = "block";
        document.getElementById("reser").focus();
        document.getElementById("main").style.filter = "blur(5px)";
        document.getElementById("mainspe").style.filter = "blur(5px)";
      } else {
        nav.style.display = "";
        document.getElementById("main").style.filter = "";
        document.getElementById("mainspe").style.filter = "";
      }
    },
    toggledown() {
      var nav = document.getElementById("recherche");
      if (nav.style.display === "block") {
        nav.style.display = "";
        document.getElementById("main").style.filter = "";
        document.getElementById("mainspe").style.filter = "";
      }
    },

    search() {
      let input = document.getElementById("reser").value;
      input = input.toLowerCase();

      input = _.deburr(input);

      let x = document.getElementsByClassName("liste_car");
      let i = 0;
      for (i = 0; i < x.length; i++) {
        if (!_.deburr(x[i].innerHTML.toLowerCase()).includes(input)) {
          x[i].style.display = "none";
        } else {
          x[i].style.display = "block";
        }
      }
    },
  },
  mounted() {
    let touti = this.listjson.map((item) => item.matiere);
    this.calculi = this.listjson;
    this.calculi = this.calculi.filter((item) => !this.error.includes(item.id));
    // only unique values
    this.touti = [...new Set(touti)];
  },
};
</script>

<style scoped>
.teli {
  overflow-x: scroll;
}

.imgdoc {
  display: flex;

  width: 50px;
  height: 50px;

  border-radius: 50%;
}

#recherche {
  display: none;
  position: fixed;
  top: 20vh;
  left: center;
  width: 100%;
}

.form__group {
  position: relative;
  padding: 15px 0 0;

  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #000000;
  outline: 0;
  font-size: 1.3rem;
  color: rgb(0, 0, 0);
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #000000;
}

.float {
  position: fixed;

  bottom: 40px;
  left: 40px;
  color: #fff;

  text-align: center;
}

.menu {
  filter: url("#shadowed-goo");
}

.menu-item,
.menu-open-button {
  background: #000000;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  margin-left: -40px;

  position: absolute;
  top: 20px;
  color: white;
  text-align: center;
  line-height: 50px;

  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
}

.menu-item:focus {
  outline: none;
  box-shadow: none;
}

.menu-open {
  display: none;
}

.hamburger {
  width: 25px;
  height: 3px;
  background: white;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12.5px;
  margin-top: -1.5px;
  transition: transform 200ms;
}

.hamburger-1 {
  transform: translate3d(0, -8px, 0);
}

.hamburger-2 {
  transform: translate3d(0, 0, 0);
}

.hamburger-3 {
  transform: translate3d(0, 8px, 0);
}

.menu-open:checked + .menu-open-button .hamburger-1 {
  transform: translate3d(0, 0, 0) rotate(45deg);
}

.menu-open:checked + .menu-open-button .hamburger-2 {
  transform: translate3d(0, 0, 0) scale(0.1, 1);
}

.menu-open:checked + .menu-open-button .hamburger-3 {
  transform: translate3d(0, 0, 0) rotate(-45deg);
}

.menu {
  position: fixed;
  left: 85%;
  /* change this */

  padding-top: 20px;

  width: 650px;
  height: 80px;
  box-sizing: border-box;
  font-size: 20px;
  text-align: left;
}

.menu-item:hover {
  background: white;
  color: #000000;
}

.menu-item:nth-child(3) {
  transition-duration: 180ms;
}

.menu-item:nth-child(4) {
  transition-duration: 180ms;
}

.menu-item:nth-child(5) {
  transition-duration: 180ms;
}

.menu-item:nth-child(6) {
  transition-duration: 180ms;
}

.menu-open-button {
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
}

.menu-open-button:hover {
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked ~ .menu-item {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.menu-open:checked ~ .menu-item:nth-child(3) {
  transition-duration: 190ms;
  transform: translate3d(-17vw, 0, 0);
}

.menu-open:checked ~ .menu-item:nth-child(4) {
  transition-duration: 290ms;
  transform: translate3d(-35vw, 0, 0);
}

.menu-open:checked ~ .menu-item:nth-child(5) {
  transition-duration: 390ms;
  transform: translate3d(-53vw, 0, 0);
}

.menu-open:checked ~ .menu-item:nth-child(6) {
  transition-duration: 490ms;
  transform: translate3d(0, -17vw, 0);
}
</style>
