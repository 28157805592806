import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';


Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/specialite',
    name: 'SpecialitesVue',
    component: () => import(/* webpackChunkName: "about" */ '@/components/pages/Specialites.vue')
  },
  {
    path: '/specialite/:specialite',
    name: 'CalculsParSpe',
    component: () => import(/* webpackChunkName: "about" */ '@/components/pages/CalculsParSpe.vue')
  },
  {
    path: '/specialite/:specialite/:calcul/:id',
    name: 'CalculPage',
    component: () => import(/* webpackChunkName: "about" */ '@/components/pages/CalculPage.vue')
  },
  {
    path: '/conseils',
    name: 'ConseilsVue',
    component: () => import(/* webpackChunkName: "about" */ '@/components/pages/ConseilsVue.vue')
  },
  {
    path: '/',
    name: 'AccueilA',
    component: () => Promise.resolve().then(() => import('@/components/pages/Accueil.vue'))
  },
  {
    path: '/conseils/non-disponible',
    name: 'NonDisponible',
    component: () => import(/* webpackChunkName: "about" */ '@/components/pages/conseils/NonDisponible.vue')
  },
  {
    path: '/conseils/problemes',
    name: 'ProBlemes',
    component: () => import(/* webpackChunkName: "about" */ '@/components/pages/conseils/Problemes.vue')
  },
  {
    path: '/conseils/recherche',
    name: 'ReCherche',
    component: () => import(/* webpackChunkName: "about" */ '@/components/pages/conseils/Recherche.vue')
  },
  {
    path: '/conseils/utilisation',
    name: 'UtiLisation',
    component: () => import(/* webpackChunkName: "about" */ '@/components/pages/conseils/Utilisation.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "about" */ '@/components/pages/NotFound.vue')
  },
  {
    path: '/about',
    //redirect to menu
    //redirect: '/'
    name: 'AbouT',
    component: () => import(/* webpackChunkName: "about" */ '@/components/pages/About.vue')
  },
  {
    path: '/privacy',
    name: 'PrivacY',
    component: () => import('@/components/pages/conseils/Privacy.vue')
  },
  
 

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
