<template>
  <div id="app">
    <div class="hid2">
    <NavBar/>
    </div>
    <router-view />
    <div class="hid">
      <BottomBar />
    </div>
  </div>
</template>

<script>
import NavBar from './components/bar/NavBar.vue'
import BottomBar from "./components/bar/BottomBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
    BottomBar,
  },
};
</script>

<style>


@font-face {
  font-family: "Roboto";
  src: url("@/assets/font/Roboto-Regular.ttf");
  font-display: swap;
}


/*desktop*/
@media only screen and (min-width: 800px) {
  .hid {
    display: none;
  }
  li {
    list-style-type: none;
  }

  input[name="Submit"] {
    position: relative;
    display: flex;
    padding: 10px 15px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    /*fit content*/
    width: fit-content;
    text-decoration: none;
  }

  hr {
    display: none;
  }

  table {
    background-color: #f2f2f200;
    border-radius: 5px;
    margin: auto;
    border-spacing: 0px;
  }

  input:checked {
    width: 1vw;
    height: 1vw;
  }

  tr:has(input:checked) {
    background-color: #d6d6e5;
    width: 30px;
    height: 30px;
    font-size: 1.2em;
    border-radius: 50px;
  }

  .score_result input {
    border: none;
    width: 10em;
    font-family: "Roboto";
    font-display: swap;
    font-size: 1.2em;
  }

  .score_result {
    align-items: center;
  }

  img {
    display: none;
  }
}

#app {
  font-family: "Roboto";

  font-display: swap;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/*mobile*/
@media only screen and (max-width: 800px) {

  .hid2 {
    display: none;
  }

  li {
    list-style-type: none;
  }

  input[name="Submit"] {
    position: relative;
    display: flex;
    padding: 10px 15px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    /*fit content*/
    width: fit-content;
    text-decoration: none;
  }

  hr {
    display: none;
  }

  table {
    background-color: #f2f2f200;
    border-radius: 5px;
    margin: auto;
    border-spacing: 0px;
  }

  input:checked {
    width: 5vw;
    height: 5vw;
  }

  tr:has(input:checked) {
    background-color: #d6d6e5;
    width: 30px;
    height: 30px;
    font-size: 1.2em;
    border-radius: 50px;
  }

  .score_result input {
    border: none;
    width: 10em;
 
    font-size: 1.2em;
  }

  .score_result {
    align-items: center;
  }

  img {
    display: none;
  }
}
</style>
